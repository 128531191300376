import React from 'react'
import styled from 'styled-components'
import { ThemeBlack } from '../constants/constants'
import { FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <Navbar>
     <a href="/">
     <img src="assets\logo.jpg" alt="" />
     </a>
     <a href="https://wa.me/19542544575?text=hello"><FaWhatsapp/>+1(954)254-4575</a>
     <a href="https://www.instagram.com/sparkling_poolsfl?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><FaInstagram/> @sparkling_poolsfl</a>
    </Navbar>
   )
 }
 
 const Navbar = styled.nav`
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: .4rem;
     height: 6rem;

     border-top: solid #0f0f0f0f 2px;
     a{
       text-decoration: none;
        color: ${ThemeBlack};
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 1rem;
        margin: 0 1rem 0 1rem;
        @media screen and (max-width: 900px) {
          font-size: 12px;
          margin: 0;
        }
     }
     img{
        height: 3rem;
        @media screen and (max-width: 900px) {
            height: 2rem;
        }
    }
 `
export default Footer
