import styled from 'styled-components'
import NavBar from './NavBar'
import Footer from './Footer'
import React from 'react'

const Layout = ({children}:{children:React.ReactNode}) => {
  return (
    <LayoutDiv>
        <NavBar/>
        {children}
        <Footer/>
    </LayoutDiv>
  )
}

const LayoutDiv = styled.div`
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow:hidden;
`

export default Layout
