import React from 'react'
import styled from 'styled-components'

const Services = () => {
  return (
    <Service>
        <img src="assets/OurServices.png" alt="" />
    </Service>
  )
}

const Service = styled.div`
    img{
        width: 100vw;
    }
`

export default Services
