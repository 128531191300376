import React from 'react'
import styled from 'styled-components'
import { ThemeLightBlue, ThemeWhite } from '../constants/constants'
import { Link } from 'react-router-dom'

const Welcome = () => {
  return (
    <WelcomeSection>
        <h1>
        Welcome to Sparkling
        Pools!
        </h1>
        <div className="imageCon">
            <img src="assets/welcome.jpg" alt="" />
            <div className="content">
                    <p>
                    Established in 2007, Sparkling Pools is a reputable
                    provider of premium maintenance and cleaning
                    services for both residential and commercial
                    properties. At Sparkling Pools, we prioritize delivering
                    complete satisfaction to our valued clientele, all
                    while maintaining a competitive pricing structure. 
                    </p>
                <div className="images">
                    <img src="assets/welcome_mini1.jpg" alt="" />
                    <img src="assets/welcome_mini2.jpg" alt="" />
                </div>
            </div>
        </div>
        <div className="button">
            <Link to={'/aboutus'}>
            More about us
            </Link>
        </div>
    </WelcomeSection>
  )
}

const WelcomeSection = styled.div`
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}
 a {
      color: ${ThemeWhite};
      padding: 0.7em 1.7em;
      font-size: 1.5rem;
      border-radius: 2em;
      font-weight: 600;
      background: ${ThemeLightBlue} ;
      cursor: pointer;
      text-decoration: none;
      border: 1px solid #e8e8e8;
      transition: all 0.3s;
      box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
      @media screen and (max-width: 900px) {

      }
    }

    a:hover {
      border: 1px solid white;
    }

    a:active {
      box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
    }
margin: 1rem;
    h1{
        font-size: 4rem;
        font-family: "Playfair", serif;
        font-optical-sizing: auto;
        font-weight: 800;
        font-style: bold;
        font-variation-settings:
    "wdth" 100;
        @media screen and (max-width: 900px) {
            font-size: 2rem;
        }
    }
    .imageCon{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
        img{
            width: 30vw;
            object-fit: cover;
            @media screen and (max-width: 900px) {
            display: none;
        }
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        p{
            font-size: 2rem;
            @media screen and (max-width: 900px) {
            font-size: 1rem;
        }
        }
    }
    .images{
        display: flex;
        gap: 3rem;
        @media screen and (max-width: 900px) {
            gap: 2rem;
        }
        img{
            height: 40vh;
            object-fit: cover;
            @media screen and (max-width: 900px) {
            width: 40vw;
            display: flex;
        }
        }
    }
`

export default Welcome
