import React from 'react'
import styled from 'styled-components'
import { ThemeLightBlue, ThemeWhite } from '../constants/constants'

const Contact = () => {
  return (
    <ContactSection>
        <img src="assets/contact.jpg" alt="" />
        <div className="container">
            <h1>
                Ready to dive in?
            </h1>
            <p>
            Ready to transform your pool into a sparkling oasis? Contact
Sparkling Pools today! We are here to provide you with exceptional
pool care services tailored to your needs. Whether you have
questions, need a quote, or want to schedule a service, we're just a
click or call away. Don't hesitate to reach out—your perfect pool is
just one step away. Click the button below to get in touch with us
now
            </p>
            <a href='#'>
                CONTACT
            </a>
        </div>
    </ContactSection>
  )
}

const ContactSection = styled.div`
    img{
        width: 100vw;
        height: 90vh;
        position: absolute;
        @media screen and (max-width: 900px) {
            height: 60vh;
        }
    }
    .container{
        background-color: #0000003b;
        width: 100vw;
        height: 90vh;
        position: relative;
        z-index: 2;
        color: ${ThemeWhite};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        @media screen and (max-width: 900px) {
            height: 60vh;
            gap: 1rem;
        }
        h1{
            margin: 0;
            font-size: 3rem;
            @media screen and (max-width: 900px) {
                font-size: 2rem;
        }
        }
        p{
            font-size: 1.5rem;
            width: 60vw;
            text-align: center;
            @media screen and (max-width: 900px) {
            width: 90vw;
            font-size: 1rem;
        }
        }
        a {
      font-weight: 600;
      color: ${ThemeWhite};
      padding: 0.7em 1.7em;
      font-size: 1.5rem;
      border-radius: 2em;
      margin: 2rem 0 0 0;
      background: ${ThemeLightBlue} ;
      cursor: pointer;
      border: 1px solid #e8e8e8;
      transition: all 0.3s;
      box-shadow: 6px 6px 12px #11111111, -6px -6px 12px #0f0f0f28;
      text-decoration: none;
      transition: 0.2s ease-out;
      @media screen and (max-width: 900px) {
        margin: 1rem;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    a:hover {
      border: 1px solid white;
      
    }

    a:active {
      box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
    }
    }
`

export default Contact
