import React from 'react'
import styled from 'styled-components'
import { ThemeDarkBlue } from '../constants/constants'

const AboutContent = () => {
  return (
    <AboutSection>
        <div className="content">
            <h1>
            About Us 
            </h1>
            <p>
            Established in 2007 and proudly serving Florida,
Sparkling Pools has built a reputation for delivering
premium maintenance and cleaning services for
both residential and commercial properties. With
years of experience and dedication to excellence,
we ensure that your pool remains in pristine
condition year-round.
            </p>
<p>
At Sparkling Pools, we are committed to providing
complete satisfaction to our valued clientele. Our team
takes pride in offering top-notch services at competitive
prices, ensuring you enjoy a clean and safe pool without
breaking the bank.
Choose Sparkling Pools for reliable, professional pool
care you can trust. Dive into a sparkling clean pool
today!
</p>
        </div>
        <img src="assets/logo.jpg" alt="" />
    </AboutSection>
  )
}

const AboutSection = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .content{
      margin: 1rem;
    }
    h1{
      font-size: 4rem;
      color: ${ThemeDarkBlue};
      @media screen and (max-width: 900px) {
        font-size: 2rem;
    }
    }
    p{
      font-size: 1.5rem;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 900px) {
        font-size: 1rem;
    }
    }
    img{
      width: 40vw;
      margin: 1rem;
      object-fit: contain;
      @media screen and (max-width: 900px) {
        width: 80vw;
    }
    }
`

export default AboutContent
