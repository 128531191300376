import Hero from "./pages/Hero";
import Layout from "./components/Layout/Layout";
import styled from "styled-components";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <Layout>
      <Toaster/>
      <MainDiv>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Hero/>}/>
            <Route path="/aboutus" element={<AboutUs/>}/>
          </Routes>
        </BrowserRouter>
      </MainDiv>
    </Layout>
  );
}

const MainDiv = styled.div`
    overflow: hidden;
`


export default App;
