import React, { useState } from 'react'
import styled from 'styled-components'
import { ThemeLightBlue, ThemeWhite } from '../constants/constants'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

const Banner = () => {

  const [name,setName] = useState<string>('')
  const [number,setNumber] = useState<string>('')
  const [email,setEmail] = useState<string>('')
  const path = useLocation()

  const HandelSubmit =()=>{
    if(name === ''){
      toast.error('Name Filed is Empty')
    }
    if(number === ''){
      toast.error('Phone Number Filed is Empty')
    }
    if(email === ''){
      toast.error('Email Filed is Empty')
    }
    else{
      return window.location.href=`https://wa.me/19542544575?text= Hey I'm Interested in your service!`
    }
  }

  console.log(path?.pathname)

  return (
    <BannerSection>
        <Content>
          <div className="written">
            {path?.pathname !== '/aboutus' && <h1>
              Your Pool Care Experts!
            </h1>}
           {path?.pathname !== '/aboutus' &&  <p>
            Experience crystal-clear water and
            pristine pool conditions in your home!
            </p>}
            {path?.pathname !== '/aboutus' && <h1>
            Get A Clean Pool!
            <img className='arrow' src="assets/arrow.png" alt="" />
            </h1>}
            {path?.pathname === '/aboutus' && <h1>
              Contact us for a
              FREE quote!
            </h1>}
          </div>
          <div className="form">
            <h1>
              Contact Us
            </h1>
            <div className="section">
              <input type="text" name="" id="" placeholder='Full Name' onChange={(e)=>setName(e.target.value)} required />
              <input type="text" name="" id="" placeholder='Phone' onChange={(e)=>setNumber(e.target.value)} required />
              <input type="email" name="" id="" placeholder='Email Address' onChange={(e)=>setEmail(e.target.value)} required />
              <button onClick={()=>HandelSubmit()} >
                Get In Touch
              </button>
            </div>
          </div>
        </Content>
        {path?.pathname !== '/aboutus' && <img src="assets\banner.jpg" alt="Banner" />}
        {path?.pathname === '/aboutus' && <img className='about' src="assets\contact.jpg" alt="Banner" />}
    </BannerSection>
  )
}

const BannerSection = styled.div`
    height: inherit;
    width: 100vw;
    margin: 5rem 0 0 0;
    img{
        width: 100vw;
        height: 450px;
        object-fit: cover;
        @media screen and (max-width: 900px) {
          height: 680px;
        } 
    }
`
const Content = styled.div`
    width: 100vw;
    height: 450px;
    background-color: #0000007d;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      height: 680px;
    }
    .written{
      margin: 2rem;
      display: flex;
      align-items: center;
      height: inherit;
      flex-direction: column;
      color: ${ThemeWhite};
      width: 50vw;
      justify-content: center;
      h1{
        font-size: 3rem;
        text-align: center;
        color: ${ThemeWhite};
        font-family: "Teachers", sans-serif;
        font-optical-sizing: auto;
        font-weight:600;
        font-style: normal;
        display: flex;
        gap: 3rem;
        .arrow{
          width: 200px;
          height: 80px;
          object-fit: contain;
          margin: -3rem -16rem 0 0;
          @media screen and (max-width: 900px) {
            display: none;
          }
        }
        @media screen and (max-width: 900px) {
          font-size: 2rem;
        }
      }
      p{
        text-align: center;
        font-size: 2rem;
        @media screen and (max-width: 900px) {
          font-size: 1rem;
        }
      }
      @media screen and (max-width: 900px) {
        width: 100vw;
        margin: 1rem;
      }
    }
    .form{
      margin: 2rem 10rem 2rem 0;
      width: 21vw;
      height: 400px;
      border-radius: 20px;
      background: #ffffff;
      box-shadow:  2px 2px 6px #0000004b,-2px -2px 10px #03030368;
      h1{
        font-size: 1.5rem;
        margin: 2rem;
        font-family: "Teachers", sans-serif;
        font-optical-sizing: auto;
        font-weight:600;
        font-style: normal;
        @media screen and (max-width: 900px) {
          margin: 1.5rem;
        }
      }
      @media screen and (max-width: 900px) {
        width: 75vw;
        margin: 1rem;
      }
    }
    .section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      input{
        width: 70%;
        padding: 9px 1rem;
        border: solid black ;
        border-radius: 20px;
        font-size: 1rem;
      }
    }
    button {
      font-weight: 600;
      color: ${ThemeWhite};
      padding: 0.7em 1.7em;
      font-size: 1.5rem;
      border-radius: 2em;
      margin: 2rem 0 0 0;
      background: ${ThemeLightBlue} ;
      cursor: pointer;
      border: 1px solid #e8e8e8;
      transition: all 0.3s;
      box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
      @media screen and (max-width: 900px) {
        margin: 1rem;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    button:hover {
      border: 1px solid white;
    }

    button:active {
      box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
    }
`

export default Banner
