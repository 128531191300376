import React from 'react'
import styled from 'styled-components'
import Banner from '../components/hero/Banner'
import Welcome from '../components/hero/Welcome'
import Review from '../components/hero/Review'
import Services from '../components/hero/Services'
import Contact from '../components/hero/Contact'

const Hero = () => {
  return (
    <HeroSection>
        <Banner/>
        <Welcome/>
        <Review/>
        <Services/>
        <Contact/>
    </HeroSection>
  )
}

const HeroSection = styled.div`
    display: flex;
    flex-direction: column;
`

export default Hero
