import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import AboutContent from '../components/AboutUs/AboutContent'
import Banner from '../components/hero/Banner'
import AboutUsSection from '../components/AboutUs/AboutUsSection'

const AboutUs = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <About>
        <AboutContent/>
        <AboutUsSection/>
        <Banner/>
    </About>
  )
}

const About = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5rem 0 0 0;
`

export default AboutUs
