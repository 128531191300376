import React from 'react'
import styled from 'styled-components'
import { ThemeWhite } from '../constants/constants'
import { FaStar } from 'react-icons/fa'

const Review = () => {
  return (
    <ReviewSection>
        <img src="assets/review_section.jpg" alt="" />
        <div className="container">
            <h1>
            Customer Reviews
            </h1>
            <div className="review">
            <div className="task">
                <div className="tags">
                  <span className="tag">~Colleen Speranza
                  </span>
                    <div className="star">
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    </div>
                </div>
                <p>Matthew is phenomenal he comes out
                whenever I need him, maintenance is on
                point. I would highly recommend him, I
                have used them for nine years and I’m
                very happy. My pool always looks great!</p>
                </div>
                <div className="dualReview">
                <div className="task">
                <div className="tags">
                  <span className="tag">~Diane M</span>
                  <div className="star">
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    </div>
                </div>
                <p>This is a family owned pool service and
                they are wonderful. They take pride in
                their work and it shows. Our pool always
                looks beautiful and "sparkling" as in their
                name. Thank you for your great service.</p>
                </div>
                <div className="task">
                <div className="tags">
                  <span className="tag">~Douglas Araujo</span>
                  <div className="star">
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    <FaStar/>
                    </div>
                </div>
                <p>Excellent professional!</p>
                </div>
                </div>
            </div>
        </div>
    </ReviewSection>
  )
}

const ReviewSection = styled.div`
    width: 100vw;
    height: auto;
    img{
        width: 100vw;
        height: 90vh;
        object-fit: cover;
        position: absolute;
    }
    .dualReview{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6rem;
        @media screen and (max-width: 900px) {
            flex-direction: column;
            gap: 1rem;
        }
    }
    .review{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        @media screen and (max-width: 900px) {
            gap: 1rem;
        }
    }
    .container{
    width: 100%;
    height: 90vh;
    background-color: #2e23013a;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1{
        margin: 2rem;
        color: ${ThemeWhite};
        font-size: 3rem;
        @media screen and (max-width: 900px) {
            font-size: 2rem;
        }
    }
    .task {
  position: relative;
  color: #2e2e2f;
  cursor: move;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  border: 3px dashed transparent;
  width: 320px;
  height: 150px;
  @media screen and (max-width: 900px) {
        width: 80vw;
        height: 120px;
        padding: 10px;
        margin: 0;
    }
}

.task:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  border-color: rgba(162, 179, 207, 0.2) !important;
}

.task p {
  font-size: 15px;
  margin: 1.2rem 0;
  @media screen and (max-width: 900px) {
        font-size: 12px;
    }
}

.tag {
  border-radius: 100px;
  padding: 4px 13px;
  font-size: 12px;
  color: #ffffff;
  background-color: #1389eb;
}
.star{
  color: gold;
}
.tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
    }

`

export default Review
