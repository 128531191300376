import React from 'react'
import styled from 'styled-components'
import { ThemeBlack, ThemeWhite } from '../constants/constants'
import { FaWhatsapp } from "react-icons/fa";

const NavBar = () => {
  return (
   <Navbar>
    <a href="/">
        <img src="assets\logo.jpg" alt="" />
    </a>
    <a href="https://wa.me/19542544575?text=hello"><FaWhatsapp/>+1(954)254-4575</a>
   </Navbar>
  )
}

const Navbar = styled.nav`
    display: flex;
    position: fixed;
    background-color: ${ThemeWhite};
    width: 100%;
    top: 0;
    z-index: 99999;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
    height: 4rem;
    margin: 0 0 3rem 0;
    border-bottom: solid #0f0f0f0f 2px;
    a{
        color: ${ThemeBlack};
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 0 1rem 0 1rem;
    }
    img{
        height: 3.5rem;
        @media screen and (max-width: 900px) {
            height: 2rem;
        }
    }
`

export default NavBar
