import React from 'react'
import styled from 'styled-components'
import { ThemeDarkBlue } from '../constants/constants'

const AboutUsSection = () => {
    return (
        <AboutUs>
                <h1>
                Meet The Owner!
                </h1>
            <div className="content">
                <img src="assets/Owner.jpg" alt="" />
                <p>
                Matheus Vanelli, a 32-year-old entrepreneur, owns and operates Sparkling Pools, a company specializing in pool services and maintenance. Known for his dedication and expertise, Matheus has built a reputation for providing high- quality services to his clients. Under his leadership, Sparkling Pools has grown and established itself as a trusted name in the industry.
                </p>
            </div>
        </AboutUs>
      )
    }
    
    const AboutUs = styled.div`
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 1rem;
        
        .content{
          display: flex;
          gap: 5rem;
          margin: 0 3rem 3rem 3rem;
@media screen and (max-width: 900px) {
          flex-direction: column;
          margin:10px;
          align-item: centre;
          justify-content: centre;
          gap: 10px;
        }
        }
        h1{
          font-size: 4rem;
          color: ${ThemeDarkBlue};
          @media screen and (max-width: 900px) {
            font-size: 2rem;
        }
        }
        p{
          font-size: 2rem;
          font-weight: 500;
          text-align: left;
          padding: 2rem;
          @media screen and (max-width: 900px) {
            font-size: 1rem;
        }
        }
        img{
          width: 30vw;
          margin: 1rem;
          object-fit: cover;
          @media screen and (max-width: 900px) {
            width: 80vw;
        }
        }
    `
    
export default AboutUsSection
